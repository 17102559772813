<template>
  <!-- 预约挂号 -->
  <div class="yuyue">
    <van-cell
      title="切换就诊人"
      icon="manager-o"
      is-link
      style="text-align: left"
      @click="openChooise()"
    >
      <template #default>
        <span style="color: #999">{{ chooiseItem.name }}</span>
      </template>
    </van-cell>
    <van-popup v-model="showPopup" position="bottom" round closeable>
      <div class="yyqr-chooise-jzk-over">
        <div class="yyqr-chooise-title">选择就诊人</div>
      </div>
      <div
        class="yyqr-chooise-jzk"
        v-for="(item, index) in list"
        :key="index"
        @click="chooiseJZPerson(item)"
      >
        <span v-if="item.id == personId" class="checked">
          <img src="@/assets/img/checked.png" alt="" />
          {{ item.name + "(" + item.idNumber + ")" }}</span
        >
        <span v-else>{{ item.name + "(" + item.idNumber + ")" }}</span>
      </div>
      <div class="yyqr-chooise-nodata" v-show="list.length == 0">
        <img
          src="../../assets/img/appoint/zanwujiuzhenren.png"
          width="100%"
          height="100%"
          alt=""
        />
      </div>
      <div class="yyqr-choose-add" @click="addPatient">
        <van-icon name="plus" />添加就诊人
      </div>
    </van-popup>
    <!-- 标签栏 -->
    <van-tabs
      v-model="activeName"
      title-active-color="#00D48B "
      color="#00D48B"
      swipeable
      @change="handleTabChange"
    >
      <van-tab title="当日预约">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <!-- 头部区域 -->
          <div v-if="showAppoint">
            <div
              class="yuyue1"
              style="margin-top: 20px"
              v-for="(item, index) in newsList[0].data"
              :key="index"
              :index="index"
            >
              <div class="yuyue2">
                <div class="yuyue3">
                  <img
                    src="@/assets/img/yisheng.png"
                    v-if="item.PortraitUrl == null || item.PortraitUrl == ''"
                    class="doctor-img"
                    alt=""
                  />
                  <img
                    :src="item.PortraitUrl"
                    v-else
                    class="doctor-img"
                    alt=""
                  />
                  <div class="yuyue4">
                    <div class="yuyue41">{{ item.Doctor }}</div>
                    <div class="yuyue42">
                      <span class="yuyue421">{{
                        item.DeptName.replace(item.Doctor, "")
                      }}</span>
                      <span>{{ item.ZhiCheng }}</span>
                    </div>
                  </div>
                </div>

                <div class="yuyue43">
                  <img
                    v-if="item.Flag == '1'"
                    src="../../assets/img/ucenter/15.png"
                    alt=""
                  />
                  <img
                    v-if="item.Flag == '3'"
                    src="../../assets/img/ucenter/17.png"
                    alt=""
                  />
                  <!-- <img v-if="item.Flag == '2'"  src="../../assets/img/ucenter/18.png" alt=""/> -->
                </div>
              </div>
              <!-- 下面区域 -->
              <div class="yuyue5">
                <div>
                  <span>就诊时间：</span
                  >{{
                    item.ClinicDate +
                    " " +
                    item.TimeDesc +
                    "  " +
                    item.CheckTime
                  }}
                </div>
                <div><span>就诊卡号：</span>{{ item.PatientId }}</div>
                <div><span>就诊序号： </span>{{ item.SeeNo }}</div>
                <div><span>来源：</span>{{ item.ReserverSource }}</div>
                <div class="yuyue6">
                  <div class="yuyue61">
                    <span style="color: #a4a5ab; padding-right: 10px"
                      >预约时间：</span
                    >{{ item.ResDate }}
                  </div>
                  <div class="yuyue8">
                    <!-- <div class="yuyue7" style="color: #8d8d8d" v-if="item.Flag == '1'"  @click="cancleAppoint(item)">取消</div> -->
                    <div class="yuyue7" @click="xiqi(item)">详情</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="baogao3" v-if="newsList[0].data.length == 0">
            <img src="../../assets/img/ucenter/21.png" alt="" />
          </div>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="历史预约" name="b">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <!-- 头部区域 -->
          <div
            class="yuyue1"
            style="margin-top: 20px"
            v-for="(item, index) in newsList[1].data"
            :key="index"
            :index="index"
          >
            <div class="yuyue2">
              <div class="yuyue3">
                <img
                  src="@/assets/img/yisheng.png"
                  v-if="item.PortraitUrl == null || item.PortraitUrl == ''"
                  class="doctor-img"
                  alt=""
                />
                <img :src="item.PortraitUrl" v-else class="doctor-img" alt="" />
                <div class="yuyue4">
                  <div class="yuyue41">{{ item.Doctor }}</div>
                  <div class="yuyue42">
                    <span class="yuyue421">{{
                      item.DeptName.replace(item.Doctor, "")
                    }}</span>
                    <span class="yuyue421">{{ item.ZhiCheng }}</span>
                  </div>
                </div>
              </div>
              <div class="yuyue43">
                <img
                  v-if="item.Flag == '1'"
                  src="../../assets/img/ucenter/15.png"
                  alt=""
                />
                <img
                  v-if="item.Flag == '3'"
                  src="../../assets/img/ucenter/17.png"
                  alt=""
                />
                <!-- <img v-if="item.Flag == '2'" src="../../assets/img/ucenter/18.png" alt=""/> -->
              </div>
            </div>
            <!-- 下面区域 -->
            <div class="yuyue5">
              <div>
                <span>就诊时间：</span
                >{{
                  item.ClinicDate + " " + item.TimeDesc + "  " + item.CheckTime
                }}
              </div>
              <div><span>就诊卡号：</span>{{ item.PatientId }}</div>
              <div><span>就诊序号： </span>{{ item.SeeNo }}</div>
              <div>
                <span style="padding-left: 0.8rem">来源：</span
                >{{ item.ReserverSource }}
              </div>
              <div class="yuyue6">
                <div class="yuyue61">
                  <span style="color: #a4a5ab; padding-right: 10px"
                    >预约时间：</span
                  >{{ item.ResDate }}
                </div>
                <div class="yuyue8">
                  <!-- <div class="yuyue7" style="color: #8d8d8d" v-if="item.Flag == '1'" @click="cancleAppoint(item)">取消</div> -->
                  <div class="yuyue7" @click="xiqi(item)">详情</div>
                </div>
              </div>
            </div>
          </div>
          <div class="baogao3" v-if="newsList[1].data.length == 0">
            <img src="../../assets/img/ucenter/21.png" alt="" />
          </div>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
    <van-dialog
      v-model="showDialog"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <!-- <template #title>
        <h3>提示</h3>
      </template> -->
      <template #default>
        <div class="a1">
          <div class="a2">
            <p class="a21">
              <img src="@/assets/img/ucenter/lingdang2.png" alt="" />
            </p>
          </div>
          <h3 class="dialog-message">提示</h3>
          <p class="dialog-message1">确定要取消预约吗？</p>
          <van-button class="cancle-btn" @click="showDialog = false"
            >取消</van-button
          >
          <van-button type="primary" class="confirm-btn" @click="submit(order)"
            >确认</van-button
          >
        </div>
        <div class="a31">
          <img
            src="../../assets/img/ucenter/20.png"
            alt=""
            @click="showDialog = false"
          />
        </div>
      </template>
    </van-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { Toast } from "vant";
export default {
  data() {
    return {
      list: [],
      showPopup: false,
      personId: "",
      isEmpty: false,
      chooiseItem: "",
      activeName: "a",
      flag: 1,
      appointList: [],
      schedules: [],
      newsList: [{ data: [] }, { data: [] }],
      isLoading: false,
      showDialog: false,
      order: {},
    };
  },
  computed: {
    showAppoint() {
      return (
        this.newsList &&
        this.newsList[0] &&
        this.newsList[0].data &&
        this.newsList[0].data.length > 0
      );
    },
    showHistoryAppoint() {
      return (
        this.newsList &&
        this.newsList[1] &&
        this.newsList[1].data &&
        this.newsList[1].data.length > 0
      );
    },
  },
  methods: {
    /**
     * 判断是否有就诊人
     */
    isHasPatient() {
      if (!this.chooiseItem) {
        this.showPopup = true;
        return;
      }
    },
    //跳转到详情页
    xiqi(item) {
      window.sessionStorage.setItem("order-detail", JSON.stringify(item));
      this.$router.push("/detail");
    },
    // 点击就诊人
    openChooise() {
      this.showPopup = true;
    },
    // 添加就诊人
    addPatient() {
      sessionStorage.setItem("backRoute", this.$route.fullPath);
      this.$router.push("/addCardOne");
    },

    chooiseJZPerson(item) {
      sessionStorage.setItem("CurrentJZPerson", JSON.stringify(item));
      this.chooiseItem = item;
      this.personId = item.id;
      this.showPopup = false;
      this.getAppoint();
    },
    getList() {
      // 病人接口
      let postData = {
        userid: sessionStorage.getItem("openid"),
        type: 1,
      };
      this.$http
        .get("/api/search/cardlist", postData)
        .then((res) => {
          if (res.status == 200) {
            this.list = res.data;
            const CurrentJZPerson = JSON.parse(
              sessionStorage.getItem("CurrentJZPerson")
            );
            if (!CurrentJZPerson) {
              if (this.list.length > 0) {
                this.personId = this.list[0].id;
                for (let i = 0; i < this.list.length; i++) {
                  if (i == 0) {
                    this.chooiseItem = this.list[i];
                  }
                  if (this.list[i].deafult) {
                    this.chooiseItem = this.list[i];
                  }
                }
                if (!this.chooiseItem.deafult) {
                  this.chooiseItem.deafult = true;
                  this.list[0].deafult = true;
                }
              }
            } else {
              this.personId = CurrentJZPerson.id;
              this.chooiseItem = CurrentJZPerson;
            }
            this.getAppoint();
            this.isHasPatient();
          }
        })
        .catch((err) => {
          console.log(err);
          this.isHasPatient();
        });
    },
    getAppoint() {
      // 获取列表数据
      this.newsList = [{ data: [] }, { data: [] }];
      let param = {
        patientId: this.chooiseItem.patId,
      };
      this.$http.get("/api/appointment/queryRegister", param).then((res) => {
        if (res.status == 200) {
          // this.appointList = res.data;
          for (let i = 0; i < res.data.length; i++) {
            // 根据时间来判断
            if (
              res.data[i].ClinicDate >=
              this.formatTime2(new Date(), "yyyy-MM-dd")
            ) {
              this.newsList[0].data.push(res.data[i]);
            } else {
              this.newsList[1].data.push(res.data[i]);
            }
          }
        }
      });
    },
    formatTime2(date, fmt) {
      //author: meizz
      var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    },
    getSchedules() {
      this.schedules = JSON.parse(
        window.sessionStorage.getItem("appoint-schedules")
      );
      for (var i = 0; i < this.schedules.length; i++) {
        var scheduleId = this.schedules[i].SchedulingId;
        var splits = scheduleId.split("_");
        this.schedules[i].title =
          moment(splits[3], "HHmmss").format("HH:mm") +
          "-" +
          moment(splits[4], "HHmmss").format("HH:mm");
      }
    },
    // 取消预约
    cancleAppoint(item) {
      this.order = item;
      this.submitData();
    },
    submitData() {
      //打开弹窗
      this.showDialog = true;
    },
    submit(item) {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        orderId: item.OrderId,
        patientId: this.chooiseItem.patId,
      };
      this.$http
        .post("/api/appointment/cancelRegister", postData)
        .then((res) => {
          console.log(res.data);
          this.getAppoint();
        })
        .catch((err) => {
          Toast.fail({ message: err.response.data });
          console.log(err);
        });
      //关闭弹窗
      this.showDialog = false;
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        this.getAppoint();
      }, 1000);
    },
    handleTabChange(name, title) {
      this.$router.replace({
        query: {
          activeName: name,
        },
      });
    },
  },
  mounted() {
    this.getList();
    this.activeName = this.$route.query.activeName;
  },
};
</script>
<style scoped>
.yuyue {
  width: 100%;
  height: 100%;
}

.yuyue1 {
  width: 9rem;
  height: 203px;
  background-color: #ffffff;
  border-radius: 10px;

  margin: auto;
}

.yuyue2 {
  height: 70px;

  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
/* .yuyue3 img {
  width: 50px;
  height: 50px;
  vertical-align: middle;
} */
.yuyue3 {
  width: 7rem;
  height: 2rem;
  display: flex;
  justify-content: space-around;
  align-content: center;
}
.yuyue4 {
  text-align: left;
}
.yuyue41 {
  margin-top: 0.2rem;
  font-size: 0.42rem;
}
.yuyue421 {
  padding-right: 10px;
  font-size: 0.4rem;
}
.yuyue422 {
  font-size: 0.45rem;
}

.yuyue43 img {
  height: 2rem;
  color: red;
  width: 2.5rem;
}
.yuyue5 {
  height: 130px;
  line-height: 25px;

  font-size: 0.4rem;
  text-align: left;
  padding: 0 10px;
}
.yuyue5 span {
  color: #a4a5ab;
  padding-right: 10px;
}
.yuyue6 {
  display: flex;
  justify-content: space-between;
}
.yuyue61 {
  width: 5.5rem;
}
.yuyue8 {
  width: 3.5rem;

  display: flex;
  justify-content: space-around;
}
.yuyue6 span:nth-of-type(1) {
  border: 0;
}

.yuyue7 {
  width: 55px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 6px;
  border: 1px solid #00d58b;
  color: #00d58b;
}

.yyqr-chooise-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}
.yyqr-chooise-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}
.yyqr-chooise-jzk {
  padding: 8px;
  font-size: 0.4rem;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #faf7fa;
}
.yyqr-chooise-jzk .checked {
  color: #03d28c;
}
.yyqr-chooise-jzk img {
  height: 0.3rem;
  width: 0.3rem;
}
.yyqr-chooise-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}
.yyqr-chooise-add .van-icon {
  position: relative;
  top: 2px;
  left: -2px;
}
.yyqr-chooise-nodata {
  font-size: 0.4rem;
  padding: 8px;
  color: #afafaf;
}
.text {
  font-size: 0.4rem;
}
.doctor-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 5px;
}
/deep/ .van-popup__close-icon--top-right {
  top: 6px;
}
.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  top: -2px;
  left: -2px;
}
.van-dialog {
  height: 400px;
  /* background-color: red; */
  background-color: rgba(0, 0, 0, 0);
}
.a1 {
  height: 200px;
  background-color: #fff;
  margin-top: 60px;
  border-radius: 20px;
  margin-bottom: 50px;
}
.a2 {
  width: 80px;
  height: 80px;
  margin: auto;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  left: 0;
  bottom: 0;
  top: -68%;
  right: 0;
}
.a21 {
  width: 65px;
  height: 65px;
  line-height: 65px;
  border-radius: 50%;
  background-color: #dbf5ec;
  margin: auto;
  margin-top: 6px;
}
.a31 {
  height: 50px;
}
.a31 img {
  width: 50px;
  height: 50px;
}
.dialog-message {
  font-size: 0.45rem;
  padding-top: 40px;
}
.dialog-message1 {
  font-size: 0.45rem;
  padding-top: 20px;
  padding-bottom: 20px;
}

.cancle-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#d2d2d2, #b9b9b9);
  color: #fff;
}
.confirm-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}
/deep/ .van-tab--active {
  background-color: #f5f5f5;
}
</style>
